import Vue from 'vue'
// import BootstrapVue  from 'bootstrap-vue/dist/bootstrap-vue.esm';
import App from '@/App.vue'
import router from '@/router/index.js'
import '@/assets/app.css'

gtag('set', 'page', router.currentRoute.path);
gtag('send', 'pageview');

router.afterEach(( to, from ) => {
  gtag('set', {'page': to.path});
  gtag('send', 'pageview');
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
